<template>
  <section>
    <v-sheet
      color="white"
      elevation="5"
      outlined
      rounded
      shaped
      class="boxauthoriation"
    >
      <v-row>
        <v-col class="text-center">
          <router-link to="/">
            <img src="@/assets/logo.svg" />
          </router-link>
        </v-col>
        <v-col md="12">
          <h3 class="text-center titleform">Verify Your Account Now</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col md="12" cols="12" v-if="email == ''">
                <v-text-field
                  v-model="email"
                  label="Email Address"
                  :rules="emailRules"
                  @keyup.enter.stop="validate"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="otp"
                  label="OTP Code"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!allLoadingAuth"
                >
                  Verify
                </v-btn>
                <v-progress-circular
                  v-if="allLoadingAuth"
                  indeterminate
                ></v-progress-circular>
                <div class="registerlink">
                  Don't have an account<router-link to="/registration"
                    >Register</router-link
                  >
                </div>

                <v-progress-circular
                  v-if="allUserloading"
                  indeterminate
                ></v-progress-circular>

                <v-snackbar v-model="snackbar"    v-if="allVerify.data != undefined">
                {{ allVerify.data.message }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-sheet>
   
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Verify",
  data() {
    return {
      allUserloading: false,
      valid: true,
      email: "",
      otp: "",
      snackbar: false,
      showpassword: false,
      emailRules: [(v) => !!v || "Phone Number or Email is required"],
      rules: {
        required: (value) => !!value || "OTP Code is required.",
      },
      
    };
  },
  methods: {
    ...mapActions(["verify"]),
    validate() {
      if (this.$refs.form.validate()) {
        let userData = {
          email: this.email,
          otp: this.otp,
        };
        this.verify(userData);
        this.snackbar = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logoutUser");
    },
  },
  computed: {
    ...mapGetters(["allLoadingAuth", "allVerify"]),
  },
  created() {
    this.snackbar = true;
    if (localStorage.getItem("emailAuth") != undefined) {
      this.email = localStorage.getItem("emailAuth");
    }
      if (localStorage.getItem("token") != undefined) {
        this.$router.push("/dashboard");
      }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.boxauthoriation {
  padding: 30px;
  width: 450px;
  border-radius: 10px !important;
}
.registerlink {
  margin-top: 15px;
  a {
    margin-left: 5px;
  }
}

.submitbtn {
  text-align: center;
}
img {
  width: 150px;
}
.form-section {
  .col-md-6,
  .col-md-12 {
    padding: 0 20px !important;
  }
}
.titleform {
  margin-bottom: 20px;
}
.registerlink {
  margin-top: 20px;
  font-size: 14px;
}
.btn-dark {
  background: $menucolor !important;
  color: $white !important;
  width: 200px;
  height: 50px !important;
}
</style>
